import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Grid,
  Paper,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import emailjs from 'emailjs-com';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';

const validationSchema = Yup.object().shape({
  store: Yup.string().required('Butik är obligatoriskt'),
  seller: Yup.string().required('Säljare är obligatoriskt'), // Add this line
  companyName: Yup.string().required('Företagsnamn är obligatoriskt'),
  orgNumber: Yup.string().required('Organisationsnummer är obligatoriskt'),
  contactName: Yup.string().required('Kontaktperson är obligatoriskt'),
  contactEmail: Yup.string().email('Ogiltig e-postadress').required('E-post är obligatoriskt'),
  contactPhone: Yup.string().required('Telefonnummer är obligatoriskt'),
  leadInfo: Yup.string().required('Lead-information är obligatoriskt'),
});

const stores = [
  { value: 'elgiganten-gislaved', label: 'Elgiganten Gislaved' },
  { value: 'elgiganten-varnamo', label: 'Elgiganten Värnamo' },
  { value: 'elgiganten-ljungby', label: 'Elgiganten Ljungby' },
  { value: 'elgiganten-vetlanda', label: 'Elgiganten Vetlanda' },
  { value: 'elgiganten-vastervik', label: 'Elgiganten Västervik' },
  { value: 'four-office', label: 'Four Office' }, // Add this line
];

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: 'none',
  borderRadius: '8px',
  maxWidth: '800px',
  width: '100%',
  maxHeight: '90vh',
  overflowY: 'auto',
  position: 'relative', // Add this line
}));

function LeadModal({ open, onClose }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const templateParams = {
      store: values.store,
      seller: values.seller, // Add this line
      companyName: values.companyName,
      orgNumber: values.orgNumber,
      contactName: values.contactName,
      contactEmail: values.contactEmail,
      contactPhone: values.contactPhone,
      leadInfo: values.leadInfo,
    };

    try {
      // Save lead to Firestore
      await addDoc(collection(db, 'leads'), {
        ...templateParams,
        status: 'New',
        owner: '', // Change this line from 'Unassigned' to an empty string
        createdAt: new Date(),
      });

      // Send email
      await emailjs.send('service_4rrh8eo', 'gigantenTemplateID', templateParams, 'KpPOagYuYxfRAW-2D');

      console.log('SUCCESS!');
      setSubmitting(false);
      resetForm();
      setSnackbarOpen(true);
      onClose();
    } catch (error) {
      console.log('FAILED...', error);
      setSubmitting(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <StyledModal open={open} onClose={onClose}>
        <StyledPaper>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" component="h2" gutterBottom>
            Ny Lead
          </Typography>
          <Formik
            initialValues={{
              store: '',
              seller: '', // Add this line
              companyName: '',
              orgNumber: '',
              contactName: '',
              contactEmail: '',
              contactPhone: '',
              leadInfo: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      select
                      name="store"
                      label="Butik"
                      fullWidth
                      margin="normal"
                      error={touched.store && errors.store}
                      helperText={touched.store && errors.store}
                    >
                      {stores.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="seller"
                      label="Säljare Elgiganten"
                      placeholder="Säljare Elgiganten"
                      fullWidth
                      margin="normal"
                      error={touched.seller && errors.seller}
                      helperText={touched.seller && errors.seller}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="companyName"
                      label="Företagsnamn"
                      fullWidth
                      margin="normal"
                      error={touched.companyName && errors.companyName}
                      helperText={touched.companyName && errors.companyName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="orgNumber"
                      label="Organisationsnummer"
                      fullWidth
                      margin="normal"
                      error={touched.orgNumber && errors.orgNumber}
                      helperText={touched.orgNumber && errors.orgNumber}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      as={TextField}
                      name="contactName"
                      label="Kontaktperson"
                      fullWidth
                      margin="normal"
                      error={touched.contactName && errors.contactName}
                      helperText={touched.contactName && errors.contactName}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      as={TextField}
                      name="contactEmail"
                      label="E-post"
                      fullWidth
                      margin="normal"
                      error={touched.contactEmail && errors.contactEmail}
                      helperText={touched.contactEmail && errors.contactEmail}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      as={TextField}
                      name="contactPhone"
                      label="Telefon"
                      fullWidth
                      margin="normal"
                      error={touched.contactPhone && errors.contactPhone}
                      helperText={touched.contactPhone && errors.contactPhone}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="leadInfo"
                      label="Lead-information"
                      fullWidth
                      margin="normal"
                      multiline
                      rows={4}
                      error={touched.leadInfo && errors.leadInfo}
                      helperText={touched.leadInfo && errors.leadInfo}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{
                      bgcolor: '#7cc53d',
                      color: 'white',
                      '&:hover': {
                        bgcolor: '#6ab22f',
                      },
                    }}
                  >
                    Skicka Lead
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </StyledPaper>
      </StyledModal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Lead skickades framgångsrikt!
        </Alert>
      </Snackbar>
    </>
  );
}

export default LeadModal;